import { toast } from "react-toastify";
import { deleteItems, updateStateData } from "../Store/actions";
import store from "../Store/store";
import CampaignIcon from "@mui/icons-material/Campaign";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { Insights, ManageAccounts, Settings } from "@mui/icons-material";
import { checkAuthorizedUserDetails, logout, sendApiRequest } from "./requestConfig";
import { capitalizeAndReplaceUnderScore, swapKeyValues, validateEmailFormat } from "./utils";

// const permissionScope = {
//   overview: {
//     can_view: true,
//     can_edit: true,
//   },
//   campaigns: {
//     can_view: true,
//     can_edit: true,
//   },
//   retail_analytics: {
//     can_view: true,
//     can_edit: true,
//   },
//   infrastructure: {
//     can_view: true,
//     can_edit: true,
//   },
//   user_management: {
//     can_view: true,
//     can_edit: true,
//   },
// };

//to validate roles information
const validateRoleInformation = () => {
  const currentStateData = store.getState().userSelections;
  const roleName = typeof currentStateData.new_role_name !== "undefined" ? currentStateData.new_role_name : "";
  const rolePermissions = typeof currentStateData.role_permissions !== "undefined" ? currentStateData.role_permissions : {};

  let error = false;
  //validate role name
  if (roleName === "") {
    error = true;
  }

  //validate role permissions
  if (Object.keys(rolePermissions).length === 0) {
    error = true;
  }

  if (error === true) {
    toast("Please enter the role name and select the permission", { error: true });
    return {
      error: error,
    };
  } else {
    return {
      error: false,
      role_requestInfo: {
        role_name: roleName,
        permissions: rolePermissions,
      },
    };
  }
};

//to validate details when adding a new user
const validateUserInformation = () => {
  const currentStateData = store.getState().userSelections;
  const userName = typeof currentStateData.username !== "undefined" ? currentStateData.username : "";
  const email = typeof currentStateData.email !== "undefined" ? currentStateData.email : "";
  const userRole = typeof currentStateData.role_name !== "undefined" ? currentStateData.role_name : "";
  const campaignIds =
    typeof currentStateData.campaign_id !== "undefined" && currentStateData.campaign_id !== null
      ? currentStateData.campaign_id
      : [];
  const userPermissions =
    typeof currentStateData.newUserPermissions !== "undefined" ? currentStateData.newUserPermissions : {};
  const cyreenUser = typeof currentStateData.cyreen_user !== "undefined" ? currentStateData.cyreen_user : false;
  // workspace_id workspace_name
  const userWorkspaceId = typeof currentStateData.workspace_id !== "undefined" ? currentStateData.workspace_id : "";
  // const userWorkspaceName = typeof currentStateData.workspace_name !== "undefined" ? currentStateData.workspace_name : "";
  let error = false;
  let errorMessages = {};
  //validate user name
  if (userName === "") {
    error = true;
    errorMessages = {
      ...errorMessages,
      user_name: "Please enter the user name",
    };
  }

  //validate user name
  if (email === "") {
    error = true;
    errorMessages = {
      ...errorMessages,
      email: "Please enter the email",
    };
  } else {
    // validate for proper email format
    const validateEmail = validateEmailFormat(email);
    if (validateEmail === false) {
      error = true;
      errorMessages = {
        ...errorMessages,
        email: "Please enter a valid email",
      };
    }
  }
  //validate user name
  if (userRole === "") {
    error = true;
    errorMessages = {
      ...errorMessages,
      user_role: "Please select the role",
    };
  }
  //validate role permissions
  if (Object.keys(userPermissions).length === 0) {
    error = true;
    errorMessages = {
      ...errorMessages,
      user_permissions: "Please select the user permissions",
    };
  }

  if (userRole === "Brand" && campaignIds.length === 0) {
    error = true;
    errorMessages = {
      ...errorMessages,
      campaign_id: "Please select the campaign",
    };
  }

  if (
    (cyreenUser === false && typeof userWorkspaceId === "undefined") ||
    (cyreenUser === false && userWorkspaceId === "")
  ) {
    error = true;
    errorMessages = {
      ...errorMessages,
      campaign_id: "Please select the Company the user belongs to",
    };
  }

  // cyreenUser
  // userWorkspaceId

  if (error === true) {
    toast("Please fill in valid information", { type: "error" });
    return {
      error: error,
      errorMessages,
    };
  } else {
    // userWorkspaceId
    let requestObj = {
      username: userName,
      email: email,
      role_name: userRole,
      permissions: userPermissions,
      campaign_ids: campaignIds,
      cyreen_user: cyreenUser,
    };
    if (userWorkspaceId !== "") {
      requestObj = {
        ...requestObj,
        workspace_id: userWorkspaceId,
      };
    }
    return {
      error: false,
      user_requestInfo: requestObj,
    };
  }
};

// to save new role
export const saveRoleInformation = () => {
  const validateData = validateRoleInformation();
};

// to fetch a list of users
export const fetchUsers = async () => {
  const getUsersList = await sendApiRequest("users", {}, "GET");
  if (typeof getUsersList.success !== "undefined" && getUsersList.success === true) {
    const usersList = getUsersList.response;

    let filteredLst = [];
    let userIdandNameList = {};
    usersList.forEach((user) => {
      if (user.email !== "" && user.email !== null) {
        filteredLst.push({
          id: user.id,
          assigned_company: user.assigned_company,
          username: user.username,
          email: user.email,
          first_name: user.first_name,
          last_name: user.last_name,
          role_name: user.role_name === null ? "Customized" : user.role_name,
          retailer_ids: user.retailer_ids,
          user_permissions: user.permissions,
          user_type: user.user_type,
          campaign_id: user.campaign_ids,
          brand_campaign_selection:
            typeof user.campaigns !== "undefined" && user.campaigns !== null
              ? user.campaigns.map((campaign) => {
                  return { id: campaign.id, label: campaign.name };
                })
              : [],
          permissions:
            user.permissions !== null && Object.keys(user.permissions).length !== 0
              ? Object.keys(user.permissions).map((accessName, index) => {
                  let permissionObj = {};
                  if (user.permissions[accessName].can_view && user.permissions[accessName].can_edit) {
                    permissionObj = {
                      [accessName]: !["overview", "retail_analytics"].includes(accessName)
                        ? "Full access for " + capitalizeAndReplaceUnderScore(accessName, false) + ": view and edit"
                        : "Full access for " + capitalizeAndReplaceUnderScore(accessName, false),
                    };
                  }
                  if (user.permissions[accessName].can_view && user.permissions[accessName].can_edit === false) {
                    permissionObj = {
                      [accessName]: !["overview", "retail_analytics"].includes(accessName)
                        ? "Limited access for " + capitalizeAndReplaceUnderScore(accessName, false) + ": view only"
                        : "Full access for " + capitalizeAndReplaceUnderScore(accessName, false),
                    };
                  }
                  if (user.permissions[accessName].can_view === false && user.permissions[accessName].can_edit === false) {
                    permissionObj = {
                      [accessName]: "Restricted",
                    };
                  }
                  return permissionObj;
                })
              : [],
        });

        userIdandNameList =
          user.role_name === "Retailer"
            ? {
                ...userIdandNameList,
                [user.id]: user.username,
              }
            : { ...userIdandNameList };
      }
    });

    store.dispatch(
      updateStateData({
        users_list: filteredLst,
        user_name_id_list: userIdandNameList,
      })
    );
    return {
      success: true,
    };
  } else {
    return {
      success: false,
    };
  }
};

// to fetch the list of companies
export const fetchCompanies = async () => {
  const getCompaniesList = await sendApiRequest("companies", {}, "GET");
  if (typeof getCompaniesList.success !== "undefined" && getCompaniesList.success === true) {
    fetchBrands();
    const companiesList = getCompaniesList.response;
    let filtredRetailerList = [];
    let companiesNameIds = [];
    companiesList.forEach((company) => {
      companiesNameIds.push({ label: company.name, id: company.id });
      filtredRetailerList.push({ label: company.name, id: company.id, tree_path: company.tree_path });
    });

    store.dispatch(
      updateStateData({
        company_name_ids: companiesNameIds,
        retailers_list: filtredRetailerList,
      })
    );
    return {
      success: true,
    };
  } else {
    return {
      success: false,
    };
  }
};

// to fetch the list of companies
export const fetchStoreLevelCompanies = async () => {
  const getCompaniesList = await sendApiRequest("companies/store-level", {}, "GET");
  if (typeof getCompaniesList.success !== "undefined" && getCompaniesList.success === true) {
    fetchBrands();
    const companiesList = getCompaniesList.response;
    let filtredRetailerList = [];
    let companiesNameIds = [];
    companiesList.forEach((company) => {
      companiesNameIds.push({ label: company.name, id: company.id });
      filtredRetailerList.push({ label: company.name, id: company.id, tree_path: company.tree_path });
    });

    store.dispatch(
      updateStateData({
        store_level_company_name_ids: companiesNameIds,
        store_level_retailers_list: filtredRetailerList,
      })
    );
    return {
      success: true,
    };
  } else {
    return {
      success: false,
    };
  }
};

export const fetchBrands = async () => {
  const getCompaniesList = await sendApiRequest("companies/advertisers", {}, "GET");
  if (typeof getCompaniesList.success !== "undefined" && getCompaniesList.success === true) {
    const companiesList = getCompaniesList.response;
    let filteredBrandList = [];
    let filteredBrandNames = [];

    companiesList.forEach((company) => {
      filteredBrandList.push({ label: company.name, id: company.id });
      filteredBrandNames.push(company.name);
    });
    filteredBrandList.push({ label: "Add new", id: "new" });
    store.dispatch(
      updateStateData({
        brand_list: filteredBrandList,
        brand_names_arr: filteredBrandNames,
      })
    );
    return {
      success: true,
    };
  } else {
    return {
      success: false,
    };
  }
};

// export const fetchAvailableToRetailerIds = async () => {
//   const getAvailableToRetailerIds = await sendApiRequest("companies/share-list", {}, "GET");
//   console.log("Retailer Ids: ", getAvailableToRetailerIds)
//   if (typeof getAvailableToRetailerIds.success !== "undefined" && getAvailableToRetailerIds.success === true) {
//     const availableRetailerList = getAvailableToRetailerIds.response;
//     store.dispatch(
//       updateStateData({
//         available_retailer_ids: availableRetailerList,
//       })
//     );
//     return {
//       success: true,
//     };
//   } else {
//     return {
//       success: false,
//     };
//   }
// };

// to save new user details
export const saveUserInformation = async (actionToPerform, actionDetail) => {
  const validateUser = validateUserInformation();
  // check for errors
  if (validateUser.error === false) {
    let apiSuccessResponse = "";
    if (actionToPerform === "add") {
      //save user information
      const saveUserInfo = await sendApiRequest("users/add", validateUser.user_requestInfo, "POST");
      apiSuccessResponse = saveUserInfo.success;
    }

    if (actionToPerform === "update") {
      let requestBody = {
        ...validateUser.user_requestInfo,
        id: actionDetail.id,
      };
      //save user information
      const saveUserInfo = await sendApiRequest("users/update", requestBody, "POST");
      apiSuccessResponse = saveUserInfo.success;
    }

    if (apiSuccessResponse === true) {
      fetchUsers();
      return {
        success: true,
      };
    } else {
      toast("Sorry, something went wrong.", { type: "error" });
      return {
        success: false,
        errorMessages: {},
      };
    }
  } else {
    return {
      success: false,
      errorMessages: validateUser.errorMessages,
    };
  }
};

export const checkUserRole = () => {
  //check if user permission cookie is available or not.
  if (typeof document.cookie.split("; ").find((row) => row.startsWith("userRole")) !== "undefined") {
    const role = JSON.parse(
      document.cookie
        .split("; ")
        .find((row) => row.startsWith("userRole"))
        .split("=")[1]
    );

    return {
      success: true,
      user_role: role,
    };
  } else {
    // if cookie doesn't exist, then we have to ask user to login again so that correct user permissions can be fetched and used for all the features to work correctly
    logout();
  }
};

// fetch user detail from the cookie
export const checkUserDetailsFromCookie = () => {
  //check if user permission cookie is available or not.
  if (typeof document.cookie.split("; ").find((row) => row.startsWith("userDetail")) !== "undefined") {
    const details = JSON.parse(
      document.cookie
        .split("; ")
        .find((row) => row.startsWith("userDetail"))
        .split("=")[1]
    );

    return {
      success: true,
      user_detail: details,
    };
  } else {
    // if cookie doesn't exist, then we have to ask user to login again so that correct user permissions can be fetched and used for all the features to work correctly
    logout();
  }
};

export const checkUserPermissions = () => {
  //check if user permission cookie is available or not.
  if (typeof document.cookie.split("; ").find((row) => row.startsWith("userPermissions")) !== "undefined") {
    const permissionScope = JSON.parse(
      document.cookie
        .split("; ")
        .find((row) => row.startsWith("userPermissions"))
        .split("=")[1]
    );
    const userRole = checkUserRole();

    const userDetails = checkUserDetailsFromCookie();

    return {
      success: true,
      allowed_access: permissionScope,
      user_role: userRole.user_role,
      user_type: userDetails.success === true ? userDetails.user_detail.user_type : "",
    };
  } else {
    // if cookie doesn't exist, then we have to ask user to login again so that correct user permissions can be fetched and used for all the features to work correctly
    logout();
  }
};

export const allowedNavOptions = async (location) => {
  //check user permissions.
  const permissions = checkUserPermissions();
  const checkUserDetails = checkUserDetailsFromCookie();

  if (permissions.success === true) {
    const permissionScope = permissions.allowed_access;
    const userRole = permissions.user_role;
    const userType = checkUserDetails.success === true ? checkUserDetails.user_detail.user_type : "";

    // Define an array of navigation items with their paths
    let navItems = [];

    // overview
    if (permissionScope.overview.can_view === true) {
      navItems = [
        ...navItems,
        {
          path: "/cap-overview",
          text: "Overview",
          icon: <DashboardIcon />,
          nested_list: false,
          active: ["/cap-overview"].includes(location.pathname),
        },
      ];
    }
    // campaigns
    if (permissionScope.campaigns.can_view === true) {
      let roleBasedAccessItems = [
        {
          path: "/check-campaigns/list",
          text: "Campaign List",
        },
      ];

      if (permissionScope.campaigns.can_edit === true) {
        roleBasedAccessItems = [
          ...roleBasedAccessItems,
          {
            path: "/check-campaigns/clips",
            text: "Clips",
          },
          {
            path: "/check-campaigns/pictures",
            text: "Pictures",
          },
          // {
          //   path: "/check-campaigns/Companies",
          //   text: "Companies",
          // },
          // {
          //   path: "/check-campaigns/Contacts",
          //   text: "Contacts",
          // },
        ];
      }
      navItems = [
        ...navItems,
        {
          path: "/check-campaigns/list",
          text: "Campaigns",
          icon: <CampaignIcon />,
          nested_list: true,
          nested_keyword: "campaign_sub_menu",
          active: [
            "/check-campaigns/list",
            "/check-campaigns/clips",
            "/check-campaigns/pictures",
            "/check-campaigns/Companies",
            "/check-campaigns/Contacts",
            "/check-campaigns/dashboard",
            "/check-campaigns/campaign-analytics/1",
            "/check-campaigns/campaign-analytics/5",
          ].includes(location.pathname),
          nested_items: roleBasedAccessItems,
        },
      ];
    }
    // retail analytics
    if (permissionScope.retail_analytics.can_view === true && userType !== "national") {
      // temporary addition of a dashboard specially for edeka..
      let nestedItemsList = [
        {
          path: "/analytics/general-kpi",
          text: "General KPIs",
        },
        {
          path: "/analytics/duration",
          text: "Instore Duration",
        },
        {
          path: "/analytics/profitability",
          text: "Profitability",
        },
        {
          path: "/analytics/trolley-usage",
          text: "Trolley Usage",
        },
        {
          path: "/analytics/heat-map",
          text: "Heatmap",
        },
        {
          path: "/analytics/optimization",
          text: "Optimization",
        },
      ];
      // root company id for edeka is 74, so we are hardcoding it to specifically show it to edeka.
      // if (checkUserDetails.success === true && checkUserDetails.user_detail.root_company_id === 74) {
      //   nestedItemsList = [
      //     ...nestedItemsList,
      //     {
      //       path: "/analytics/special-analytics",
      //       text: "Special Analytics",
      //     },
      //   ];
      // }
      navItems = [
        ...navItems,
        {
          path: "/analytics/general-kpi",
          text: "Retail Analytics",
          icon: <Insights />,
          nested_list: true,
          nested_keyword: "retail_analytics",
          active: [
            "/analytics/general-kpi",
            "/analytics/instore-duration",
            "/analytics/profitability",
            "/analytics/trolley-usage",
            "/analytics/duration",
            "/analytics/heat-map",
            "/analytics/optimization",
            "/analytics/special-analytics",
          ].includes(location.pathname),
          nested_items: nestedItemsList,
        },
      ];
    }
    // infrastructure
    if (permissionScope.infrastructure.can_view === true && userType !== "national") {
      const infraNavOptions =
        userRole.toLowerCase() === "admin"
          ? [
              {
                path: "/infrastructure/locations",
                text: "Stores",
              },
              {
                path: "/infrastructure/monitoring",
                text: "Monitoring",
              },
            ]
          : [
              {
                path: "/infrastructure/monitoring",
                text: "Monitoring",
              },
            ];
      navItems = [
        ...navItems,
        {
          path: "/infrastructure/locations",
          text: "Infrastructure",
          icon: <Settings />,
          nested_list: true,
          nested_keyword: "infrastructure_sub_menu",
          active: [
            "/infrastructure/locations",
            "/infrastructure/devices",
            "/infrastructure/monitoring",
            "/infrastructure/configure-location",
          ].includes(location.pathname),
          nested_items: infraNavOptions,
        },
      ];
    }
    // user management
    if (userRole.toLowerCase() === "admin") {
      navItems = [
        ...navItems,
        {
          path: "/users/list",
          text: "Users",
          icon: <ManageAccounts />,
          nested_list: false,
          nested_keyword: "user_management_menu",
          active: ["/users/list", "/users/role"].includes(location.pathname),
          nested_items: [
            {
              path: "/users/list",
              text: "Users",
            },
            // {
            //   path: "/users/role",
            //   text: "Roles / Permissions",
            // },
          ],
        },
      ];
    }

    return {
      success: true,
      navItems: navItems,
    };
  } else {
    // if cookie doesn't exist, then we have to ask user to login again so that correct user permissions can be fetched and used for all the features to work correctly
  }
};

// export const pageAccessAllowed = (routeToAccess, whichAction = "can_view") => {
//   //check allowed access
//   const permissions = checkUserPermissions();

//   if (permissions.success === true) {
//     const permissionScope = permissions.allowed_access;

//     if (permissionScope[routeToAccess][whichAction] === false) {
//       return {
//         success: false,
//         message: "Sorry, you are not allowed to access this page",
//       };
//     } else {
//       return {
//         success: true,
//         message: "access allowed",
//       };
//     }
//   } else {
//     return {
//       success: false,
//       message: "Sorry, something went wrong",
//     };
//   }
// };
export const pageAccessAllowed = (routeToAccess, whichAction = "can_view", allowedUserTypes = []) => {
  // Check allowed access
  const permissions = checkUserPermissions();

  if (permissions.success === true) {
    const permissionScope = permissions.allowed_access;
    const userType = permissions.user_type;

    // Check if user type is allowed
    if (allowedUserTypes.length > 0 && !allowedUserTypes.includes(userType)) {
      return {
        success: false,
        message: "Sorry, your user type is not allowed to access this page",
      };
    }

    if (permissionScope[routeToAccess][whichAction] === false) {
      return {
        success: false,
        message: "Sorry, you are not allowed to access this page",
      };
    } else {
      return {
        success: true,
        message: "Access allowed",
      };
    }
  } else {
    return {
      success: false,
      message: "Sorry, something went wrong",
    };
  }
};

// to reset password for the logged user
export const resetUserPassword = async (passwordInfo) => {
  //update password
  const updateInfo = await sendApiRequest("users/update-password", { password: passwordInfo }, "POST");
  if (typeof updateInfo.success !== "undefined" && updateInfo.success === true) {
    logout();
  } else {
    toast("Sorry, something went wrong.", { type: "error" });
  }
};

// to send forgot password email
export const forgotUserPassword = async (userEmail) => {
  //update password
  const sendInfo = await sendApiRequest("authenticate/password-reset", { email: userEmail }, "POST");

  if (typeof sendInfo.success !== "undefined" && sendInfo.success === true) {
    toast(sendInfo.response.message, { type: "success" });
  } else {
    toast("Sorry, something went wrong.", { type: "error" });
  }
};

// switch workspace
export const switchWorkspace = async (workspaceId, navigate, usedFor) => {
  //update password
  const sendInfo = await sendApiRequest("workspaces/switch/" + workspaceId, {}, "POST");

  if (typeof sendInfo.success !== "undefined" && sendInfo.success === true) {
    if (usedFor === "update") {
      toast(sendInfo.response.message, { type: "success" });
    }

    store.dispatch(
      updateStateData({
        show_workspace_list: false,
      })
    );
    checkAuthorizedUserDetails(navigate);
  } else {
    toast("Sorry, something went wrong.", { type: "error" });
  }
};

export const checkWorkSpaceFromCookies = () => {
  let currentWorkSpace = {};
  let workspaceOptions = [];
  //check if user permission cookie is available or not.
  if (typeof document.cookie.split("; ").find((row) => row.startsWith("workspace_options")) !== "undefined") {
    workspaceOptions = JSON.parse(
      document.cookie
        .split("; ")
        .find((row) => row.startsWith("workspace_options"))
        .split("=")[1]
    );

    if (typeof document.cookie.split("; ").find((row) => row.startsWith("current_workspace")) !== "undefined") {
      currentWorkSpace = JSON.parse(
        document.cookie
          .split("; ")
          .find((row) => row.startsWith("current_workspace"))
          .split("=")[1]
      );
    }

    return {
      success: true,
      workspace_options: workspaceOptions,
      current_workspace: currentWorkSpace,
    };
  } else {
    return {
      success: false,
      workspace_options: [],
      current_workspace: {},
    };
  }
};
